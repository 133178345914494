<template>
  <div>
    <div class="search">
      <div class="title">核销点列表</div>
      <hr />
      <el-form :inline="true" class="top">
        <el-form-item label="核销点名称:">
          <el-input v-model="params.redeemName"></el-input>
        </el-form-item>
        <el-form-item label="状态:">
          <el-select v-model="params.status" placeholder="请选择">
            <el-option label="全部" :value="0"></el-option>
            <el-option label="正常" :value="1"></el-option>
            <el-option label="禁用" :value="2"></el-option>
          </el-select>
        </el-form-item>

        <el-form-item>
          <el-button type="primary" @click="getList">查询</el-button>
          <el-button type="primary" @click="reset">重置</el-button>
        </el-form-item>
      </el-form>
    </div>
    <el-table
      :data="tableData"
      border
      tooltip-effect="dark"
      :row-key="rowKey"
      style="width: 100%"
      align="center"
      @selection-change="handleSelectionChange"
    >
      <el-table-column
        type="selection"
        width="55"
        :reserve-selection="true"
        align="center"
      >
      </el-table-column>
      <el-table-column type="index" width="50" align="center"> </el-table-column>
      <el-table-column prop="redeemName" label="核销点名称" align="center">
      </el-table-column>

      <el-table-column prop="address" label="地址" width="200" align="center">
      </el-table-column>
      <el-table-column prop="strStatus" label="状态" width="180" align="center">
      </el-table-column>
      <el-table-column prop="addTime" label="添加时间" width="200" align="center">
      </el-table-column>
      <el-table-column label="操作" align="center">
        <template slot-scope="scope">
          <el-button type="primary" size="mini" @click="submit(scope.row)">添加</el-button>
        </template>
      </el-table-column>
    </el-table>
    <div class="block">
      <!-- <div>
                <el-button type="primary" @click="allCheckVip">批量修改vip</el-button>
              <el-button type="primary" @click="allOpe">批量操作</el-button>
           </div> -->
      <div>
        <el-pagination
          @size-change="handleSizeChange"
          @current-change="handleCurrentChange"
          :current-page="params.currentPage"
          :page-sizes="[5, 10, 20]"
          :page-size="params.pageSize"
          layout="total,sizes,prev,pager,next, jumper"
          :total="total"
        >
        </el-pagination>
      </div>
    </div>
    <span slot="footer" class="dialog-footer">
      <el-button @click="$emit('close')">取 消</el-button>
      <el-button type="primary" @click="submit('')">确 定</el-button>
    </span>
  </div>
</template>

<script>
import { writeoffForUserListById,writeoffForUserListAdd } from "../../../api/administrator.js";
export default {
  props: {
    userId: {
      type: [Number, String],
      default: 0,
    },
  },
  data() {
    return {
      dialogVisible: false,
      total: 0,
      params: {
        currentPage: 1,
        pageSize: 5,
        redeemName: "",
        status: 0,
      },
      ids: "",
    
    
      tableData: [],
      date: null,
      pickerOptions0: {
        disabledDate(time) {
          return time.getTime() > Date.now() - 8.64e6;
        },
      },
    };
  },
  created() {
    this.getList();
  },
  methods: {
    handleSelectionChange(val) {
      this.ids = val.map((item) => item.redeemId).join(",");
    },
    rowKey(row) {
      return row.redeemId;
    },
    async getList() {
      //   this.params.beginTime = this.date ? this.date[0] : "";
      //   this.params.endTime = this.date ? this.date[1] : "";
      const { data } = await writeoffForUserListById({
        ...this.params,
        userId: this.userId,
      });
      if (data.code == 0) {
        this.tableData = data.list;
        this.total = data.pagination.total;
      } else {
      }
    },
    handleSizeChange(e) {
      this.params.pageSize = e;
      this.getList();
    },
    // 页数改变
    handleCurrentChange(e) {
      this.params.currentPage = e;
      this.getList();
    },
    reset() {
      this.params.currentPage = 1;
      this.params.pageSize = 5;
      this.params.status = 0;
      this.params.redeemName = "";

      this.date = null;
      this.getList();
    },
    add() {
      this.dialogVisible = true;
    },
    edit(row) {
      Object.keys(this.addList).forEach((key) => {
        this.addList[key] = row[key];
      });
      this.dialogVisible = true;
    },
    upData(row, status) {
      let str = "";
      switch (status) {
        case 0:
          str = "删除";
          break;
        case 1:
          str = "禁用";
          break;
        case 2:
          str = "启用";
          break;
      }
      this.$confirm("是否确认" + str + "?", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      })
        .then(async () => {
          const { data } = await writeOffListUpdata({
            redeemId: row.redeemId,
            status,
          });
          if (data.code == 0) {
            this.$message.success(data.msg);
            this.getList();
          } else {
            this.$message.error(data.msg);
          }
        })
        .catch(() => {});
    },
   async submit(row = {}) {
      let params = { userId: this.userId };
      if (!row) {
        //点击下面的提交
        if (!this.ids) {
          this.$message.error("请选择核销点");
          return;
        }
        params.redeemIds = this.ids;
      } else {
        //点击添加
        params.redeemIds = row.redeemId;
      }
      if (this.load) return;
          this.load = true;
          const { data } = await writeoffForUserListAdd(params);
          this.load = false;
          if (data.code == 0) {
            this.$message.success(data.msg);
            this.dialogVisible = false;
            this.getList();
          } else {
            this.$message.error(data.msg);
          }
    },
  },
};
</script>

<style scoped>
.title {
  padding: 10px 0;
}
.top {
  margin-top: 20px;
}
.block {
  margin-top: 15px;
  display: flex;
  justify-content: flex-end;
  width: 100%;
}
</style>
